import { Text, View } from '@cnd/elements'
import colors from '@constants/colors'

export const Tab = ({ icon, name }) => {
  return (
    <View
      style={{ position: 'relative', cursor: 'pointer', marginRight: 10, marginBottom: 3 }}
      motion
      whileTap={{ scale: 0.93 }}
    >
      <View
        style={{
          zIndex: 0,
          top: 3,
          right: -3,
          background: colors.nnWhite,
          marginRight: 0,
          marginBottom: 0,
          width: 90,
          height: 64,
          position: 'absolute',
          borderRadius: 20,
        }}
      />
      <View
        center
        style={{
          zIndex: 1,
          width: 90,
          height: 64,
          paddingTop: 10,
          position: 'relative',
          border: `1px solid rgba(0,0,0,0.9)`,
          borderRadius: 20,
        }}
      >
        {icon}
        <Text
          style={{
            textTransform: 'uppercase',
            fontSize: 11,
            marginTop: 3,
            color: '#000000',
            paddingBottom: 10,
          }}
        >
          {name}
        </Text>
      </View>
    </View>
  )
}
