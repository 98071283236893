import { useWindowSize } from 'usehooks-ts'
import Rolls from './icons/Rolls'
import Curries from './icons/Curries'
import Rice from './icons/Rice'
import Drinks from './icons/Drinks'
import Desserts from './icons/Desserts'
import Sides from './icons/Sides'
import BaoBurger from './icons/BaoBurger'
import { View } from '@cnd/elements'

export const Tabs = ({ children }) => {
  const { width } = useWindowSize()
  return (
    <View row style={{ overflow: 'hidden', zIndex: 10 }}>
      <View
        row
        style={{
          width: '100%',
          overflowX: 'scroll',
          paddingLeft: width < 500 ? 15 : 0,
          justifyContent: width < 500 ? 'flex-start' : 'center',
          height: 68,
        }}
      >
        {children}
      </View>
    </View>
  )
}

export const TABS = [
  { name: 'Curries', id: -1, order: -1, icon: (color) => <Curries size={35} color={color} />, type: 'curry' },
  {
    name: 'Burgers',
    id: 1,
    order: 1,
    icon: (color) => <BaoBurger size={20} color={color} />,
    type: 'burger',
  },

  { name: 'Rolls', id: 3, order: 3, icon: (color) => <Rolls size={35} color={color} />, type: 'roll' },
  { name: 'Sides', id: 4, order: 4, icon: (color) => <Sides size={35} color={color} /> },
  { name: 'Rice', id: 5, order: 5, icon: (color) => <Rice size={35} color={color} />, type: 'rice' },
  { name: 'Drinks', id: 6, order: 6, icon: (color) => <Drinks size={35} color={color} />, type: 'drink' },
  {
    name: 'Desserts',
    id: 7,
    order: 7,
    icon: (color) => <Desserts size={35} color={color} />,
    type: 'dessert',
  },
  {
    name: 'Ice Cream',
    id: 8,
    order: 8,
    icon: (color) => <Desserts size={35} color={color} />,
    type: 'icecream',
  },
]
