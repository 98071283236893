import { useState, useEffect } from 'react'
import { SubHeading, GreenButton, AnimatedTextInput, Error, View, Spacer } from '@cnd/elements'
import { setDoubleSidebarBottomIsOpen, setSidebarBottomIsOpen } from '@store/actions'
import { useTypedSelector, useTypedDispatch } from '@store'
import usePostUser from 'hooks/usePostUser'
import { useSafeAreaInsets } from '@components/SafeAreaView'
import { validateEmail } from '@cnd/redash'
import useUser from '@store/useUser'

const UpdateEmail = () => {
  const insets = useSafeAreaInsets()
  const dispatch = useTypedDispatch()
  const postUser = usePostUser()
  const pending = useUser('pending')

  const isOpen = useTypedSelector((state) => state.doubleSidebarBottom.isOpen)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const saveEmail = async () => {
    if (validateEmail(email)) {
      try {
        await postUser({ email })
        close()
      } catch (error) {
        setEmailError('Your email was not saved, please try again later.')
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      setEmailError('')
      setEmail('')
    }
  }, [isOpen])

  const close = () => {
    dispatch(setDoubleSidebarBottomIsOpen(false))
  }

  return (
    <View
      style={{
        padding: '10px 10px 15px 10px',
        position: 'relative',
        maxWidth: 550,
        paddingBottom: insets.bottom + 25,
        margin: '0 auto',
        width: '100%',
      }}
    >
      <View spaceBetween center row>
        <SubHeading>Update email</SubHeading>
        <GreenButton
          disabled={!validateEmail(email) || pending}
          onClick={saveEmail}
          style={{ marginRight: 15 }}
        >
          Save
        </GreenButton>
      </View>

      <Spacer height={10} />
      <AnimatedTextInput placeholder="Email" value={email} setValue={setEmail} autoCapitalize={'off'} />
      <Spacer height={25} />
      {emailError.length !== 0 && <Error>{emailError}</Error>}

      <Spacer height={255} />
    </View>
  )
}

export default UpdateEmail
