import { useTypedSelector } from '@store'
import useUser from '@store/useUser'
import { reject, values } from 'ramda'

const useUserMenu = () => {
  const isUserVegan = useUser('isVegan')
  const isUserVeggie = useUser('isVeggie')
  const isUserHalal = useUser('isHalal')

  const menu = useTypedSelector((s) => s.menu.items)

  const filteredMenu = reject<any, any>(({ isVegan, isVeggie, isHalal }) => {
    let reject = false
    if (isUserVegan && !isVegan) reject = true
    if (isUserHalal && !isHalal) reject = true
    if (isUserVeggie && !isVeggie) reject = true

    return reject
  }, values(menu))

  return filteredMenu
}

export default useUserMenu
