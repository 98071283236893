import { View } from '@cnd/elements'
import { memo } from 'react'

const Bottom = ({ children, zIndex = 9 }) => (
  <View
    center
    style={{
      zIndex,
      position: 'absolute',
      padding: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }}
  >
    <View
      center
      style={{
        zIndex,
        paddingBottom: 30,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        width: '100%',
        maxWidth: 420,
      }}
      row
    >
      {children}
    </View>
  </View>
)

export default memo(Bottom)
