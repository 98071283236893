const ratio = 95 / 74

const Desserts = ({ size, color = '#777777' }) => {
  return (
    <svg
      width={size * ratio}
      height={size}
      viewBox="0 0 95 74"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desserts" transform="translate(0.853796, 0.481182)" fillRule="nonzero">
          <path
            d="M19,72.4258639 C17.6,72.1258639 13.7,70.7258639 10.3,69.2258639 C2,65.6258639 0,62.1258639 0,51.4258639 C0,44.4258639 0.3,43.1258639 3.2,38.3258639 C6.3,33.2258639 8.4,31.3258639 17.7,25.6258639 C20.2,24.1258639 22.6,21.6258639 23.4,19.7258639 C25.8,13.9258639 32.7,7.32586393 39.8,3.92586393 C52.6,-2.27413607 68.7,-1.07413607 80.6,7.12586393 C89.4,13.1258639 93.9,21.1258639 94,30.5258639 C94,34.7258639 93.4,37.5258639 92.1,39.7258639 C89.3,44.2258639 81.6,49.2258639 76.4,49.8258639 C72.1,50.2258639 72,50.4258639 72,53.9258639 C72,60.2258639 68.3,65.1258639 60.7,68.9258639 C54,72.4258639 54,72.4258639 37.7,72.6258639 C28.8,72.7258639 20.4,72.6258639 19,72.4258639 Z M51.2,62.9258639 C59.2,61.0258639 60.4,60.4258639 62.1,57.6258639 C64.4,53.8258639 64.5,51.1258639 62.4,46.2258639 C60.4,41.2258639 55.6,36.4258639 49.5,33.2258639 C45.2,30.9258639 43.3,30.6258639 35.5,30.6258639 C25.6,30.6258639 21.4,32.1258639 14.6,38.1258639 C6.8,44.9258639 5.2,56.4258639 11.5,60.2258639 C20.2,65.2258639 36.5,66.3258639 51.2,62.9258639 Z M77.5,40.4258639 C86.6,36.6258639 88,28.1258639 81,19.0258639 C76.6,13.3258639 64.8,8.02586393 56.2,8.02586393 C51,8.02586393 38.5,13.6258639 35,17.6258639 C31.4,21.7258639 31.4,22.0258639 34,21.3258639 C37.6,20.4258639 48.3,23.1258639 54.7,26.6258639 C60.8,30.0258639 69,38.0258639 69,40.6258639 C69,42.5258639 72.9,42.4258639 77.5,40.4258639 Z"
            id="pfXUzm5b2"
            fill="#F6F3F2"
          ></path>
          <path
            d="M26.5,62.0258639 C11.8,60.0258639 6.9,54.9258639 11.4,46.1258639 C13.4,42.3258639 20.2,36.4258639 24.7,34.5258639 C30.3,32.1258639 41.5,32.2258639 47,34.7258639 C58.3,39.8258639 64.5,51.9258639 58.6,57.3258639 C54.2,61.4258639 38.7,63.7258639 26.5,62.0258639 Z M44.9,52.9258639 C48.5,52.3258639 51.8,51.5258639 52.2,51.1258639 C53.7,49.8258639 49.7,45.8258639 44.6,43.4258639 C38,40.3258639 33,40.4258639 26.3,43.7258639 C21.2,46.1258639 17,50.3258639 18.3,51.6258639 C18.8,52.2258639 30,53.6258639 35.4,53.9258639 C37,53.9258639 41.3,53.5258639 44.9,52.9258639 Z"
            id="pF98TzCAq"
            fill="#F6F3F2"
          ></path>
          <path
            d="M22.6,70.9258639 C14.4,69.7258639 6.9,66.0258639 3.4,61.5258639 C-4.1,51.6258639 3.7,33.7258639 18.3,27.3258639 C21.2,26.0258639 22.8,24.2258639 25.2,19.7258639 C30.3,10.2258639 39,4.22586393 50.7,2.02586393 C66.8,-0.874136068 84.3,7.62586393 90.8,21.2258639 C96.5,33.5258639 90.5,44.4258639 75.8,48.1258639 C71.4,49.1258639 71,49.5258639 71,52.2258639 C71,58.8258639 65.9,65.3258639 58.4,68.5258639 C51,71.6258639 34.7,72.7258639 22.6,70.9258639 Z M53,64.4258639 C60.2,62.4258639 63.9,59.5258639 64.9,55.0258639 C68.1,40.5258639 48.2,25.6258639 30.2,29.0258639 C13.6,32.1258639 2.1,46.4258639 7.2,57.5258639 C8.5,60.4258639 13.8,63.5258639 19.7,64.9258639 C26.3,66.5258639 46.8,66.2258639 53,64.4258639 Z M80,40.7258639 C89.2,36.2258639 89.9,26.8258639 81.8,17.5258639 C79.6,15.0258639 75.4,11.7258639 72.6,10.2258639 C68,7.82586393 66.4,7.52586393 57.5,7.52586393 C48.6,7.52586393 46.9,7.82586393 42.4,10.2258639 C37.6,12.7258639 30,19.9258639 30,22.0258639 C30,22.6258639 32.4,23.0258639 35.4,23.0258639 C42.6,23.0258639 52.9,26.3258639 58.2,30.3258639 C60.6,32.1258639 64.2,35.9258639 66.4,38.8258639 C70.7,44.6258639 71.5,44.7258639 80,40.7258639 Z"
            id="pkktDtsac"
            fill="#110E11"
          ></path>
          <path
            d="M21.8,59.7258639 C10,57.0258639 8,50.2258639 16.8,41.8258639 C21.8,36.9258639 26.3,34.9258639 32.9,34.3258639 C43.4,33.3258639 53.7,38.2258639 57.9,46.2258639 C61.3,52.4258639 60.3,55.5258639 54.4,58.2258639 C48.5,60.7258639 30.2,61.6258639 21.8,59.7258639 Z M50.3,53.9258639 C54.6,52.8258639 55.1,50.7258639 51.9,46.6258639 C44.6,37.3258639 26.4,37.3258639 19.1,46.6258639 C16.4,50.1258639 16.4,52.7258639 19.3,53.6258639 C25.6,55.7258639 42.4,55.8258639 50.3,53.9258639 Z"
            id="p13wyj6qqb"
            fill="#110E11"
          ></path>
          <path
            d="M23,69.5258639 C12.1,67.7258639 5.8,64.2258639 3,58.5258639 C1.3,55.0258639 1,53.0258639 1.5,50.2258639 C3.1,41.5258639 10.4,32.4258639 19.4,28.0258639 C22.5,26.5258639 24.2,24.7258639 26.4,20.4258639 C30.9,11.9258639 41.1,4.82586393 51.1,3.22586393 C67.8,0.725863932 85.8,10.1258639 90.4,23.7258639 C92.4,29.5258639 92.4,31.8258639 90.5,36.3258639 C88.5,41.1258639 83.8,44.4258639 76.3,46.5258639 C69.9,48.3258639 69.4,48.9258639 69.1,54.3258639 C68.8,62.3258639 62.7,66.7258639 48.2,69.5258639 C39.1,71.3258639 33.3,71.3258639 23,69.5258639 Z M54.4,65.6258639 C57.1,64.8258639 60.8,62.6258639 62.6,60.9258639 C65.6,58.0258639 66,57.0258639 66,52.4258639 C66,45.6258639 64.9,43.0258639 60,37.6258639 C47.4,24.0258639 23.1,24.0258639 11,37.7258639 C7.3,42.0258639 4,49.3258639 4,53.3258639 C4,56.1258639 8.5,61.7258639 12.3,63.7258639 C20.5,68.0258639 43.3,69.0258639 54.4,65.6258639 Z M81.5,41.6258639 C86.7,38.8258639 89.4,33.6258639 88.5,28.0258639 C87,19.4258639 77.4,9.72586393 67.5,6.82586393 C65.3,6.12586393 60.4,5.62586393 56.5,5.62586393 C48.5,5.52586393 41.1,8.42586393 34.7,14.0258639 C31,17.2258639 27.4,22.7258639 28.3,23.7258639 C28.6,23.9258639 32.1,24.3258639 36.2,24.5258639 C49.1,25.1258639 59.1,30.4258639 66.1,40.6258639 C69,44.9258639 69.2,45.0258639 73.3,44.4258639 C75.6,44.0258639 79.3,42.8258639 81.5,41.6258639 Z"
            id="p1DAELe6ZE"
            fill={color}
          ></path>
          <path
            d="M19.9,58.1258639 C14.3,56.3258639 13,55.0258639 13,51.6258639 C13,43.3258639 24,35.7258639 36,35.8258639 C48.4,35.9258639 58.4,43.1258639 57.8,51.5258639 C57.4,57.5258639 54.7,58.4258639 37,58.7258639 C29,58.8258639 21.3,58.6258639 19.9,58.1258639 Z M52.9,54.5258639 C56.6,52.7258639 56.7,51.8258639 53.9,47.4258639 C49.3,39.9258639 36.5,36.0258639 27.6,39.4258639 C22.1,41.5258639 16,47.3258639 16,50.4258639 C16,55.5258639 22.1,57.1258639 39.2,56.5258639 C46.4,56.3258639 50.8,55.6258639 52.9,54.5258639 Z"
            id="p15ZnKtljt"
            fill={color}
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Desserts
