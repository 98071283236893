const BaoBurger = ({ size = 35, color = '#777777' }) => {
  return (
    <svg width={(size * 623.6) / 600} height={size} viewBox="0 0 623.666667 600" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth={40}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="BaoBurger" transform="translate(33.3333, 33.3333)" stroke={color} strokeWidth="66.6666667">
          <path
            d="M362.05,366.666667 L456.146795,254.043333 C467.225525,240.783333 482.253385,233.333333 497.921795,233.333333 L501.3,233.333333 C532.06311,233.333333 557,263.18 557,300 L557,300 C557,336.82 532.06311,366.666667 501.3,366.666667 L55.7,366.666667 C24.9377255,366.666667 0,336.82 0,300 L0,300 C0,263.18 24.9377255,233.333333 55.7,233.333333 L181.434952,233.333333 C225.7521,233.333333 268.253985,254.403333 299.59359,291.913333 L362.05,366.666667 Z"
            id="Path"
            strokeWidth={40}
          ></path>
          <path
            d="M27.85,366.666667 L529.15,366.666667 L529.15,433.333333 C529.15,488.563333 491.744665,533.333333 445.6,533.333333 L111.4,533.333333 C65.2567275,533.333333 27.85,488.563333 27.85,433.333333 L27.85,366.666667 Z"
            id="Path"
            strokeWidth={40}
          ></path>
          <path
            d="M278.5,0 C149.741652,0 43.6590525,58.5253333 29.464186,200.03 C27.630542,218.31 40.319002,233.333333 55.7,233.333333 L501.3,233.333333 C516.681555,233.333333 529.370015,218.31 527.5347,200.03 C513.339555,58.5253333 407.258905,0 278.5,0 Z"
            id="Path"
            strokeWidth={40}
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default BaoBurger
