import { View } from '@cnd/elements'
import { absoluteFillObject } from '@services/StyleSheet'
import { memo } from 'react'
import { useWindowSize } from 'usehooks-ts'

const MenuBackground = ({ children }) => {
  const { width, height } = useWindowSize()
  if (width === 0 || height === 0) return null

  return (
    <View
      style={{
        ...absoluteFillObject,
        backgroundImage: width < height ? `url(/bg.webp)` : `url(/bg_wide_r.webp)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {children}
    </View>
  )
}

export default memo(MenuBackground)
