import React, { CSSProperties, useEffect, useState } from 'react'

import { AnimatePresence, LoadingAnimation, Spacer, View } from '@cnd/elements'
import { closeDoubleSidebarBottom } from '@store/actions'
import LaterDelivery from './SlideOvers/LaterDelivery'
import UpdateEmail from './SlideOvers/UpdateEmail'
import AddDeliveryLocation from './SlideOvers/AddDeliveryLocation'
import PaymentMethodPicker from './SlideOvers/PaymentMethodPicker'
import AddPaymentMethod from './SlideOvers/AddPaymentMethod'
import Help from './SlideOvers/Help'
import LoginModal from './Login/LoginModal'
import { useTypedDispatch, useTypedSelector } from '@store'
import ChooseMembership from './SlideOvers/ChooseMembership'

import SelectTimeWorked from './SlideOvers/SelectTimeWorked'
import { useAnimation } from '@cnd/elements'

import useKeyboard from '@hooks/useKeyboard'
import { useSafeAreaInsets } from './SafeAreaView'
import { useWindowSize } from 'usehooks-ts'
import { indexOf } from 'ramda'
import { absoluteFillObject } from '@services/StyleSheet'
import { isApp } from '@services/platform'

let LoadStripe

if (isApp) {
  LoadStripe = require('./LoadStripeApp').default
} else {
  LoadStripe = require('./LoadStripeWeb').default
}

const hideKeybordspaceron = ['Checkout']

const calculateModalPositionData = (windowHeight, topInset, bottomInset) => {
  const topMargin = windowHeight > 700 ? 85 : windowHeight < 400 ? 0 : 55
  return { height: windowHeight - topInset - bottomInset - topMargin }
}

export const DoubleSidebarBottom = ({ pageId }) => {
  const [keyboardHeight] = useState(300)
  const { bottom, top } = useSafeAreaInsets()
  const { height } = useWindowSize()

  const { isOpen, content } = useTypedSelector((state) => state.doubleSidebarBottom)
  const dispatch = useTypedDispatch()
  const controls = useAnimation()
  const { Keyboard } = useKeyboard()

  const closeSidebar = () => {
    console.log('Hiding')
    Keyboard.hide()
    dispatch(closeDoubleSidebarBottom())
  }

  useEffect(() => {
    if (isOpen) controls.start('visible')
    if (!isOpen) controls.start('hidden')
  }, [isOpen])

  const { height: modalHeight } = calculateModalPositionData(height, top, bottom)

  return (
    <>
      <View
        motion
        key={`double_sidebar_bottom_content__${pageId}`}
        exit="hidden"
        initial={{ height: 0 }}
        animate={{ height: isOpen ? modalHeight - 30 : 0 }}
        style={{
          background: '#FFFFF3',
          zIndex: 22,
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: modalHeight,
          overflow: 'hidden',
          borderTopLeftRadius: 7,
          borderTopRightRadius: 7,
          borderTop: `4px solid #2D1619`,
        }}
      >
        <View
          motion
          style={{ overflow: 'scroll', height: modalHeight }}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
        >
          {content === 'LaterDelivery' && <LaterDelivery />}
          {content === 'UpdateEmail' && <UpdateEmail />}
          {content === 'AddDeliveryLocation' && <AddDeliveryLocation />}
          {content === 'SelectTimeWorked' && <SelectTimeWorked />}
          {content === 'Help' && <Help />}
          {content === 'PaymentMethodPicker' && (
            <LoadStripe>
              <PaymentMethodPicker />
            </LoadStripe>
          )}
          {content === 'AddPaymentMethod' && (
            <LoadStripe>
              <AddPaymentMethod />
            </LoadStripe>
          )}
          {content === 'LoginModal' && <LoginModal />}
          {content === 'ChooseMembership' && <ChooseMembership />}
          {indexOf(content, hideKeybordspaceron) === -1 && <Spacer height={keyboardHeight} />}
        </View>
      </View>

      <View
        motion
        onClick={closeSidebar}
        key={`double_sidebar_bottom_overlay__${pageId}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 0.8 : 0 }}
        transition={{ type: 'spring' }}
        style={{
          pointerEvents: isOpen ? 'auto' : 'none',
          position: 'absolute',
          zIndex: 20,
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          backgroundColor: `#2E161E`,
        }}
      />
    </>
  )
}

interface ShrinkOnSidebarOpenProps {
  style?: CSSProperties
  children: any
  background?: string
}

const variants = {
  isOpen: {
    scale: 0.94,
    transition: { type: 'spring', stiffness: 100 },
  },
  isClosed: {
    scale: 1,
    transition: { type: 'spring', stiffness: 100 },
  },
}

export const ShrinkOnDoubleSidebarOpen = ({ children }: ShrinkOnSidebarOpenProps) => {
  const isOpen = useTypedSelector((state) => state.doubleSidebarBottom.isOpen)

  return (
    <AnimatePresence>
      <View style={absoluteFillObject} motion animate={isOpen ? `isOpen` : `isClosed`} variants={variants}>
        {children}
      </View>
    </AnimatePresence>
  )
}
