import React, { useRef, useState } from 'react'
import Head from 'next/head'
import { Loading, View, AnimatePresence, Spacer, useScroll } from '@cnd/elements'

import { useTypedSelector } from '@store'
import { PageWithSidebarBottom, ShrinkOnSidebarOpen } from '@components/PageWithSidebarBottom'
import MenuItems from '@components/MenuPage/MenuItems'

import Bottom from './Bottom'
import MenuCategories from './MenuCategories'

import {
  BackdropDismisser,
  ChoosePostCodeButton,
  OrderTimeAndDestinationPicker,
  useUpdateOrderLocation,
} from '@components/UpdateOrderLocation'

import { showHideVariants } from '@services/animate'
import MenuButton from '@components/MenuButton'

import { useTranslation } from 'react-i18next'
import { DoubleSidebarBottom, ShrinkOnDoubleSidebarOpen } from '@components/DoubleSidebarBottom'
import BasketAndOrderButtons from './BasketAndOrderButtons'
import useUserMenu from './useUserMenu'
import MenuBackground from './MenuBackground'
import WereClosed from './WereClosed'
import useWindowSize from '@hooks/useWindowSize'
import { absoluteFillObjectZ } from '@services/StyleSheet'

const wideNarrowVariants = {
  wide: { width: 400, x: (56 + 15) / 2 },
  narrow: { width: 330 },
}

const moveUpAndStick = {
  up: {
    y: 0,
    borderRadius: [25, 0],
  },
  down: {
    y: 120,
    borderRadius: [0, 25],
  },
}

const MenuAnimateToTop = ({ children }) => {
  const [up, setUp] = useState(false)
  const menuRef = useRef(null)

  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    container: menuRef,
  })

  scrollYProgress.on('change', (e) => {
    if (e > 0.02 && !up) setUp(true)
    if (e <= 0.02 && up) setUp(false)
  })

  return (
    <AnimatePresence>
      <View
        id={`menu-scroll-view`}
        ref={menuRef}
        motion
        animate={up ? 'up' : 'down'}
        variants={moveUpAndStick}
        transition={{ duration: 0.6, type: 'spring' }}
        style={{
          padding: 0,
          zIndex: 1,
          borderTopLeftRadius: 35,
          borderTopRightRadius: 35,
          background: 'white',
          overflow: 'scroll',
          borderTop: `4px solid #96b8a4`,
          display: 'block',
        }}
      >
        {children}
      </View>
    </AnimatePresence>
  )
}

const MenuPage = () => {
  const { t } = useTranslation()

  const { stopChoosing } = useUpdateOrderLocation()
  const choosingLocation = useTypedSelector((s) => s.locations.choosingLocation)
  const menu = useUserMenu()

  return (
    <>
      <Head>
        <meta
          name={t('description')}
          content={t('Browse our home made classics and order from our menu today. We deliver to your door.')}
        />
      </Head>
      <ShrinkOnDoubleSidebarOpen>
        <MenuBackground>
          <ShrinkOnSidebarOpen>
            <AnimatePresence>
              {choosingLocation && <BackdropDismisser zIndex={4} setOpen={stopChoosing} />}
            </AnimatePresence>

            <View
              row
              center
              style={{
                position: 'absolute',
                top: 40,
                width: '95%',
                padding: 10,
                minWidth: 300,
                zIndex: 5,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <ChoosePostCodeButton
                motion
                variants={wideNarrowVariants}
                animate={choosingLocation ? 'wide' : 'narrow'}
              />

              <View
                motion
                style={{ marginLeft: 15, pointerEvents: choosingLocation ? 'none' : 'auto' }}
                animate={choosingLocation ? 'hide' : 'show'}
                variants={showHideVariants}
              >
                <MenuButton />
              </View>
            </View>
            {choosingLocation && (
              <View style={{ ...absoluteFillObjectZ(4) }}>
                <OrderTimeAndDestinationPicker />
              </View>
            )}

            <MenuAnimateToTop>
              <WereClosed />
              <Spacer height={40} />
              <MenuCategories />
              <Spacer height={20} />
              {!menu && <Loading />}
              {!!menu && <MenuItems menu={menu} />}
            </MenuAnimateToTop>

            <Bottom zIndex={3}>
              <BasketAndOrderButtons />
            </Bottom>
          </ShrinkOnSidebarOpen>
        </MenuBackground>
        <PageWithSidebarBottom pageId={`menu`} />
      </ShrinkOnDoubleSidebarOpen>
      <DoubleSidebarBottom pageId={`menu`} />
    </>
  )
}
export default MenuPage
