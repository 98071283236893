import React from 'react'
import Router from 'next/router'

import { priceToPounds } from '@cnd/common/functions/menu'
import Image from 'next/image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faSeedling } from '@fortawesome/free-solid-svg-icons'
import { Spacer, View, Text } from '@cnd/elements'
import ImageShimmer from '../../ImageSimmer'
import PlusMinusCounter from '../../PlusMinusCounter'
import { useWindowSize } from 'usehooks-ts'
import { useTimer } from 'react-timer-hook'
import { useTranslation } from 'react-i18next'
import useOrder from '@store/useOrder'

const VeganBadge = () => {
  const { t } = useTranslation()
  return (
    <View row style={{ alignItems: 'center', padding: 3 }}>
      <FontAwesomeIcon icon={faSeedling} style={{ fontSize: 12, opacity: 0.8 }} color={'#055e07'} />
      <div style={{ marginLeft: 5, color: '#055e07' }}>{t('Vegan')}</div>
    </View>
  )
}

const HalalBadge = () => {
  const { t } = useTranslation()
  return (
    <View row style={{ alignItems: 'center', padding: 3 }}>
      <Text style={{ fontSize: 12, opacity: 0.8 }} weight={800} color={'black'}>
        حلال
      </Text>
      <div style={{ marginLeft: 5, color: 'black' }}>{t('Halal')}</div>
    </View>
  )
}

const VeggieBadge = () => {
  const { t } = useTranslation()
  return (
    <View row style={{ alignItems: 'center', padding: 3 }}>
      <FontAwesomeIcon icon={faSeedling} style={{ fontSize: 12, opacity: 0.8 }} color={'brown'} />
      <div style={{ marginLeft: 5, color: 'brown' }}>{t('Veggie')}</div>
    </View>
  )
}

const ComingSoonCountDown = ({ dateAvailable }) => {
  const { minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(dateAvailable),
    onExpire: () => console.warn('onExpire called'),
  })
  return (
    <View row spaceBetween>
      <View>
        <Text bold size={16}>
          {days}
        </Text>
        <Text size={12}>Days</Text>
      </View>
      <View>
        <Text bold size={16}>
          {hours}
        </Text>
        <Text size={12}>Hours</Text>
      </View>
      <View>
        <Text bold size={16}>
          {minutes}
        </Text>
        <Text size={12}>Mins</Text>
      </View>
    </View>
  )
}

const ComingSoon = ({ dateAvailable }) => {
  return (
    <View
      style={{
        position: 'absolute',
        background: '#e33226',
        zIndex: 1,
        paddingBlock: 28,
        paddingInline: 35,
        borderRadius: 20,
        boxShadow: `0px 0px 0px 5px rgb(255,255,255)`,
      }}
    >
      <Text style={{ color: 'white', textTransform: 'uppercase', textAlign: 'center' }}>
        <Text size={18} bold style={{ borderBottom: '4px solid white', paddingBottom: 7 }}>
          Coming soon
        </Text>
        <Spacer height={7} />
        <ComingSoonCountDown dateAvailable={dateAvailable} />
      </Text>
    </View>
  )
}

const checkIfIsComingSoon = (dateAvailable) => {
  if (!dateAvailable) return false
  if (new Date(dateAvailable).valueOf() - new Date().valueOf() > 0) return true
  return false
}

const MenuItem = ({ item, addItem, removeItem, numberInBasket }) => {
  const { t } = useTranslation()
  const { width } = useWindowSize()
  const deliveryLocation = useOrder('deliveryLocation')
  //const membershipState = useUser('membership').shortname
  const membershipState = deliveryLocation?.['pricingName'] || 'hiro'
  const membershipPriceLogo = deliveryLocation?.['pricingLogo'] || false
  const {
    id,
    enabled,
    title,
    description,
    price,
    image,
    isVegan,
    isVeggie,
    optionGroups,
    releaseDate,
    isHalal,
  } = item

  const isComingSoon = checkIfIsComingSoon(releaseDate)

  if (!enabled) return null

  const goToItem = (e) => {
    console.log('JSON.stringify({ goToItem: true, e })')
    Router.push(`/MenuItems/[itemId]`, `/MenuItems/${id}/`)
  }

  const removeThisItem = () => {
    removeItem(id)
  }
  const addThisItem = () => {
    addItem(id)
  }

  return (
    <View center style={{ maxWidth: 530, margin: '0 auto', position: 'relative' }}>
      {isComingSoon && <ComingSoon dateAvailable={releaseDate} />}
      <View
        row
        style={{
          paddingTop: 25,
          paddingBottom: 25,
          opacity: isComingSoon ? 0.6 : 1,
          paddingInline: !!image ? 0 : 30,
          minWidth: 300,
        }}
      >
        {!!image && (
          <View
            row
            center
            style={{
              width: '50vw',
              justifyContent: 'flex-end',
              marginRight: width > 500 ? 30 : 0,
              cursor: 'pointer',
            }}
            onClick={goToItem}
          >
            <ImageShimmer
              alt={'Product image'}
              src={image}
              width={215}
              height={215}
              style={{
                objectFit: 'cover',
                position: 'relative',
                right: width > 500 ? 0 : 15,
                borderRadius: 50,
              }}
            />
          </View>
        )}
        <View
          style={{
            width: !!image ? '50vw' : 'auto',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: width > 500 ? 20 : 0,
          }}
        >
          <div onClick={goToItem} style={{ cursor: 'pointer' }}>
            <Text bold size={18} style={{ marginRight: 10 }}>
              {t(title)}
            </Text>
            <div
              style={{
                opacity: 0.8,
                minWidth: !!image ? 163 : 'auto',
                paddingRight: 15,
                maxWidth: !!image ? 246 : '100%',
              }}
            >
              {t(description)}
            </div>
            <View row style={{ flexWrap: 'wrap' }}>
              {isVegan && <VeganBadge />}
              {isVeggie && <VeggieBadge />}
              {isHalal && <HalalBadge />}
            </View>

            <Spacer height={8} />
            {deliveryLocation && (
              <View row>
                <Text size={14}>£{priceToPounds(price)}</Text>
                {!!membershipPriceLogo && (
                  <View row style={{ marginLeft: 10 }}>
                    <Image width={100} height={20} src={membershipPriceLogo} alt={'Price logo'} />
                    <Text size={14} style={{ marginLeft: 5, opacity: 0.7, textTransform: 'capitalize' }}>
                      {t('price')}
                    </Text>
                  </View>
                )}
                {!membershipPriceLogo && (
                  <Text size={14} style={{ marginLeft: 10, opacity: 0.7, textTransform: 'capitalize' }}>
                    {membershipState} {t('price')}
                  </Text>
                )}
              </View>
            )}

            <Spacer height={10} />
          </div>

          {!optionGroups && (
            <PlusMinusCounter plus={addThisItem} minus={removeThisItem} count={numberInBasket} />
          )}
          {optionGroups && (
            <View onClick={goToItem} style={{ cursor: 'pointer' }}>
              <View row style={{ alignItems: 'center', cursor: 'pointer' }}>
                <Text weight={400} size={15}>
                  {t('Customise')}
                </Text>
                <FontAwesomeIcon icon={faArrowRight} fontSize={15} style={{ paddingLeft: 10 }} />
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

export default MenuItem
