import React, { useCallback } from 'react'
import { View, B, Text } from '@cnd/elements'
import { useTypedDispatch, useTypedSelector } from '@store'
import { setSidebarBottom } from '@store/actions'
import { useRouter } from 'next/navigation'
import { useUpdateOrderLocation } from '@components/UpdateOrderLocation'

import Pill from '@components/Pill'
import { useTranslation } from 'react-i18next'
import { values } from 'ramda'
import ColoredButton from '@components/ColoredButton'
import { PiBasketDuotone } from 'react-icons/pi'
import { HiCog } from 'react-icons/hi'
import colors from '@constants/colors'

const BasketAndOrderButtons = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useTypedDispatch()
  const { startChoosing } = useUpdateOrderLocation()
  const orderInProgress = useTypedSelector((state) => values(state.ordersInProgress).length > 0)

  const whenAndWhereSet = useTypedSelector((s) => s.user.order.whenAndWhereSet)
  const numberOfItems = useTypedSelector((state) => state.user.order.itemsCount)

  const goToBasket = useCallback(() => {
    dispatch({ type: 'SET_CHECKOUT_STEP', payload: 'BASKET' })
    dispatch(setSidebarBottom({ content: 'Checkout', isOpen: true, height: 'auto' }))
  }, [])

  const gotToPrizeGame = useCallback(() => {
    router.push('./PrizeGame')
  }, [])

  const clearOrdersInProgressAndStartChoosing = (e) => {
    dispatch({ type: 'CLEAR_ORDERS_IN_PROGRESS' })
    console.log('RUNNING clearOrdersInProgressAndStartChoosing')
    startChoosing(e)
  }

  if (orderInProgress)
    return (
      <>
        <ColoredButton color={colors.nBlack()} onClick={clearOrdersInProgressAndStartChoosing} size={50} full>
          <Text color="white" weight={500} size={18} style={{ paddingInline: 20 }}>
            Start Order
          </Text>
        </ColoredButton>
        <ColoredButton color={'#fc5e18'} onClick={goToBasket} size={55}>
          <View
            motion
            animate={{ rotate: [0, 360] }}
            initial={{ rotate: 0 }}
            transition={{ repeat: Infinity, duration: 5, repeatType: 'loop' }}
            style={{ position: 'absolute', top: 26, right: 10 }}
          >
            <HiCog color="rgba(255,255,255,0.8)" size={17} />
          </View>
          <PiBasketDuotone color="white" size={25} />
        </ColoredButton>
      </>
    )

  if (!whenAndWhereSet)
    return (
      <ColoredButton color={colors.nBlack()} onClick={startChoosing} size={50} full>
        <Text color="white" weight={500} size={22} style={{ paddingInline: 20 }}>
          Start Order
        </Text>
      </ColoredButton>
    )
  {
    /* <RosetteButton onClick={gotToPrizeGame} /> */
  }
  return (
    <ColoredButton
      color={numberOfItems > 0 ? '#fc5e18' : colors.nBlack()}
      onClick={goToBasket}
      size={55}
      auto
    >
      <View row>
        <Text
          color="white"
          weight={500}
          size={22}
          style={{ paddingLeft: 20, paddingRight: numberOfItems > 0 ? 10 : 20 }}
        >
          {t('BASKET')}
        </Text>
        {numberOfItems > 0 && (
          <Pill color="white" style={{ paddingInline: 10, paddingTop: 4, marginLeft: 5, marginRight: 20 }}>
            <B size={17} color={'#212121'} style={{ position: 'relative', top: -2 }}>
              {numberOfItems}
            </B>
          </Pill>
        )}
      </View>
    </ColoredButton>
  )
}
export default BasketAndOrderButtons
