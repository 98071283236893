import { Spacer, Text, View } from '@cnd/elements'
import { getDay } from 'date-fns'
import { useTranslation } from 'react-i18next'

const WereClosed = () => {
  const { t } = useTranslation()
  if (true || getDay(new Date()) === 0) return null
  return (
    <>
      <View center>
        <View
          center
          style={{
            backgroundColor: 'rgba(255,0,0,0.15)',
            paddingBlock: 15,
            paddingInline: 25,
            borderRadius: 20,
            maxWidth: 300,
          }}
        >
          <Text bold center>
            {t("We're closed on Sundays")}
          </Text>
          <Text center size={12}>
            {t('You can book your order for another day!')}
          </Text>
        </View>
      </View>
      <Spacer height={20} />
    </>
  )
}

export default WereClosed
