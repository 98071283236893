import { Element } from 'react-scroll'

import MenuItem from './MenuItem'

import { addItemToOrder, removeItemFromOrderByItemId } from '@store/orderActions'

import filter from 'lodash/filter'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import useOrder from '@store/useOrder'

import { Spacer, Text, View } from '@cnd/elements'

import { Header } from './Header'
import { prop, groupBy, keys, includes, not } from 'ramda'
import { ProductOnMenu } from '@cnd/common/types'
import { findById } from '@cnd/redash'
import { useTypedDispatch } from '@store'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const mapSorted = (items, itteratee) => map(sortBy(items, ['order']), itteratee)

interface MenuItemProps {
  menu: ProductOnMenu[]
}

const MenuItems = ({ menu }: MenuItemProps) => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const items = useOrder('items')
  const { disabledCategories = [] } = (useOrder('deliveryLocation') as any) || {}

  let menuInCategories = groupBy(prop('type'), menu)

  const addItem = (menuId) => {
    const item = findById(menuId, menu)
    dispatch(addItemToOrder(item))
  }
  const removeItem = (itemId) => {
    const action = removeItemFromOrderByItemId(items, itemId)
    if (action) dispatch(action)
  }

  const getQuantity = (itemId) => filter(items, ['id', itemId]).length

  return (
    <View style={{ zIndex: 0 }}>
      {keys(menuInCategories.curry).length > 0 && (
        <Header theId={'Curries'} description={t('Your favourite home made dishes')}>
          <Text size={24} bold block={false}>
            {t('Classics')}
          </Text>
        </Header>
      )}

      <Element name={'Curries'} />
      {mapSorted(menuInCategories.curry, (item) => (
        <MenuItem
          key={item.id}
          item={item}
          addItem={addItem}
          removeItem={removeItem}
          numberInBasket={getQuantity(item.id)}
        />
      ))}

      {mapSorted(menuInCategories.poke, (item) => (
        <MenuItem
          key={item.id}
          item={item}
          addItem={addItem}
          removeItem={removeItem}
          numberInBasket={getQuantity(item.id)}
        />
      ))}

      {not(includes('burgers', disabledCategories || [])) && (
        <>
          {keys(menuInCategories.burger).length > 0 && (
            <Header
              theId={'Burgers'}
              description={t('Bao bun burgs with awesome fillings, served with fries.')}
            >
              <Text size={24} bold block={false}>
                {t('Bao Burgers')}
              </Text>
            </Header>
          )}

          <Element name={'Burgers'} />
          {mapSorted(menuInCategories.burger, (item) => (
            <MenuItem
              key={item.id}
              item={item}
              addItem={addItem}
              removeItem={removeItem}
              numberInBasket={getQuantity(item.id)}
            />
          ))}
        </>
      )}

      {not(includes('rolls', disabledCategories || [])) && (
        <>
          {keys(menuInCategories.roll).length > 0 && (
            <Header
              theId={'Rolls'}
              description={t('Giant sushi rolls with a crispy center, imagine if KFC and Sushi had a baby')}
            >
              <Text size={24} bold block={false}>
                {t('Crispy Rolls')}
              </Text>
            </Header>
          )}

          {mapSorted(menuInCategories.roll, (item) => (
            <MenuItem
              key={item.id}
              item={item}
              addItem={addItem}
              removeItem={removeItem}
              numberInBasket={getQuantity(item.id)}
            />
          ))}
        </>
      )}

      {not(includes('sides', disabledCategories || [])) && (
        <>
          {keys(menuInCategories.side).length > 0 && (
            <Header theId={'Sides'}>
              <Text size={24} bold block={false}>
                {t('Sides')}
              </Text>
            </Header>
          )}

          <Element name={'Sides'} />
          {mapSorted(menuInCategories.side, (item) => (
            <MenuItem
              key={item.id}
              item={item}
              addItem={addItem}
              removeItem={removeItem}
              numberInBasket={getQuantity(item.id)}
            />
          ))}
        </>
      )}

      {not(includes('rice', disabledCategories || [])) && (
        <>
          {keys(menuInCategories.rice).length > 0 && (
            <Header theId={'Rice'}>
              <Text size={24} bold block={false}>
                {t('Rice')}
              </Text>
            </Header>
          )}
          <div id={'Rice'} />
          <Element name={'Rice'} />
          {mapSorted(menuInCategories.rice, (rice) => (
            <MenuItem
              key={rice.id}
              item={rice}
              addItem={addItem}
              removeItem={removeItem}
              numberInBasket={getQuantity(rice.id)}
            />
          ))}
        </>
      )}

      {not(includes('drinks', disabledCategories || [])) && (
        <>
          {keys(menuInCategories.drink).length > 0 && (
            <Header theId={'Drinks'} description={'Served with ice'}>
              <Text size={24} bold block={false}>
                {t('Drinks')}
              </Text>
            </Header>
          )}

          <Element name={'Drinks'} />
          {mapSorted(menuInCategories.drink, (drink) => (
            <MenuItem
              key={drink.id}
              item={drink}
              addItem={addItem}
              removeItem={removeItem}
              numberInBasket={getQuantity(drink.id)}
            />
          ))}
        </>
      )}

      {not(includes('desserts', disabledCategories || [])) && (
        <>
          <Element name={'Desserts'} />
          {keys(menuInCategories.dessert).length > 0 && (
            <Header theId={'Desserts'} description={'All baked treats are served warm from the oven'}>
              <Text size={24} bold block={false}>
                {t('Desserts')}
              </Text>
            </Header>
          )}

          {mapSorted(menuInCategories.dessert, (dessert) => (
            <MenuItem
              key={dessert.id}
              item={dessert}
              addItem={addItem}
              removeItem={removeItem}
              numberInBasket={getQuantity(dessert.id)}
            />
          ))}
        </>
      )}

      {not(includes('icecream', disabledCategories || [])) && (
        <>
          <Element name={'Ice cream'} />
          {keys(menuInCategories.dessert).length > 0 && (
            <Header theId={'Ice cream'} description={'Perfect with our cookies and brownies!'}>
              <Text size={24} bold block={false}>
                {t('Ice cream')}
              </Text>
            </Header>
          )}

          {mapSorted(menuInCategories.icecream, (icecream) => (
            <MenuItem
              key={icecream.id}
              item={icecream}
              addItem={addItem}
              removeItem={removeItem}
              numberInBasket={getQuantity(icecream.id)}
            />
          ))}
        </>
      )}

      {/* <div ref={sidesRef} />
      <Element name={'Sides'} />
      {mapSorted(sides, (side) => (
        <MenuItem
          key={side.id}
          item={side}
          addItem={addItem}
          removeItem={removeItem}
          numberInBasket={getQuantity(side.id)}
        />
      ))} */}

      <Spacer />
    </View>
  )
}
export default memo(MenuItems)
