import { TABS, Tabs } from '@components/MenuPage/MenuItems/Tabs'
import { Tab } from '@components/MenuPage/MenuItems/Tab'
import { map } from 'ramda'
import { Link } from 'react-scroll'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { memo } from 'react'

const MenuCategories = () => {
  const { t } = useTranslation()
  return (
    <Tabs>
      {map(
        ({ name, id, icon }) => (
          <Link
            to={name}
            containerId={'menu-scroll-view'}
            smooth={true}
            duration={500}
            offset={-270}
            key={id}
          >
            <Tab icon={icon('#000')} name={t(name)} />
          </Link>
        ),
        orderBy(TABS, ['order'])
      )}
    </Tabs>
  )
}

export default memo(MenuCategories)
