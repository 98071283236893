import { useTypedDispatch, useTypedSelector } from '@store'
import { setSidebarBottom } from '@store/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { View } from '@cnd/elements'

import { ordersInProgress } from '@store/useOrder'
import ColoredButton from './ColoredButton'

const MenuButton = ({ ...rest }) => {
  const { isOpen } = useTypedSelector((s) => s.sidebarBottom)
  const dispatch = useTypedDispatch()
  const toggleMenu = () => {
    dispatch(setSidebarBottom({ isOpen: !isOpen, content: isOpen ? null : 'Menu' }))
  }

  const areOrdersInProgress = useTypedSelector((state) => ordersInProgress(state.user.orders))

  return (
    <ColoredButton color="white" onClick={toggleMenu} size={56} borderWidth={1} margin={0} {...rest}>
      {areOrdersInProgress && (
        <View
          style={{
            borderRadius: 10,
            position: 'absolute',
            top: 0,
            right: 0,
            background: '#ea4444',
            width: 15,
            height: 15,
            border: `2px solid white`,
          }}
        />
      )}
      <FontAwesomeIcon icon={faUser} onClick={toggleMenu} fontSize={18} />
    </ColoredButton>
  )
}

export default MenuButton
